import { createRouter, createWebHistory } from 'vue-router'
import CurlGenerator from '../components/CurlGenerator.vue'
import ElasticsearchGenerator from '../components/ElasticsearchGenerator.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: CurlGenerator
  },
  {
    path: '/es',
    name: 'Elasticsearch',
    component: ElasticsearchGenerator
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router