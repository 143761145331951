<template>
  <div class="generator-container">
    <el-card class="box-card">
      <h2 class="card-title">Elasticsearch CURL 请求生成器</h2>
      <el-form @submit.prevent="generateCurl" label-position="top">
        <el-form-item label="Elasticsearch URL">
          <el-input v-model="esUrl" placeholder="http://localhost:9200"></el-input>
        </el-form-item>
        
        <el-form-item label="索引名称">
          <el-input v-model="indexName" placeholder="my_index"></el-input>
        </el-form-item>
        
        <el-form-item label="查询类型">
          <el-select v-model="queryType" placeholder="选择查询类型">
            <el-option label="Match Query" value="match"></el-option>
            <el-option label="Term Query" value="term"></el-option>
            <el-option label="Range Query" value="range"></el-option>
            <el-option label="Bool Query" value="bool"></el-option>
            <el-option label="Multi Match Query" value="multi_match"></el-option>
            <el-option label="Exists Query" value="exists"></el-option>
            <el-option label="Prefix Query" value="prefix"></el-option>
            <el-option label="Wildcard Query" value="wildcard"></el-option>
            <el-option label="Regexp Query" value="regexp"></el-option>
            <el-option label="Fuzzy Query" value="fuzzy"></el-option>
          </el-select>
        </el-form-item>
        
        <!-- 根据查询类型动态生成查询构建器 -->
        <div v-if="queryType === 'match'">
          <el-form-item label="字段">
            <el-input v-model="matchField" placeholder="字段名"></el-input>
          </el-form-item>
          <el-form-item label="值">
            <el-input v-model="matchValue" placeholder="查询值"></el-input>
          </el-form-item>
        </div>
        
        <div v-if="queryType === 'term'">
          <el-form-item label="字段">
            <el-input v-model="termField" placeholder="字段名"></el-input>
          </el-form-item>
          <el-form-item label="值">
            <el-input v-model="termValue" placeholder="查询值"></el-input>
          </el-form-item>
        </div>
        
        <div v-if="queryType === 'range'">
          <el-form-item label="字段">
            <el-input v-model="rangeField" placeholder="字段名"></el-input>
          </el-form-item>
          <el-form-item label="起始值">
            <el-input v-model="rangeFrom" placeholder="起始值"></el-input>
          </el-form-item>
          <el-form-item label="结束值">
            <el-input v-model="rangeTo" placeholder="结束值"></el-input>
          </el-form-item>
        </div>
        
        <div v-if="queryType === 'bool'">
          <el-form-item label="Must 条件">
            <el-input v-model="boolMust" placeholder="Must 条件"></el-input>
          </el-form-item>
          <el-form-item label="Should 条件">
            <el-input v-model="boolShould" placeholder="Should 条件"></el-input>
          </el-form-item>
          <el-form-item label="Must Not 条件">
            <el-input v-model="boolMustNot" placeholder="Must Not 条件"></el-input>
          </el-form-item>
        </div>
        
        <!-- 其他查询类型的输入字段 -->
        
        <el-form-item label="生成的DSL">
          <el-input
            type="textarea"
            :rows="6"
            v-model="generatedDsl"
            readonly
          ></el-input>
        </el-form-item>
        
        <el-form-item label="生成的CURL命令">
          <el-input
            type="textarea"
            :rows="4"
            v-model="generatedCurl"
            readonly
          ></el-input>
        </el-form-item>
        
        <el-form-item>
          <div class="button-group">
            <el-button type="primary" @click="generateCurl" icon="el-icon-refresh">生成CURL</el-button>
            <el-button type="success" @click="copyDsl" icon="el-icon-document-copy">复制DSL</el-button>
            <el-button type="info" @click="copyCurl" icon="el-icon-document">复制CURL</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <el-button type="primary" @click="toggleHelp" class="toggle-help">{{ showHelp ? '隐藏' : '显示' }}帮助信息</el-button>
    <CurlHelp v-if="showHelp" type="elasticsearch" />
  </div>
</template>

<script>
import CurlHelp from './CurlHelp.vue';

export default {
  name: 'ElasticsearchGenerator',
  components: {
    CurlHelp,
  },
  data() {
    return {
      esUrl: '',
      indexName: '',
      queryType: '',
      matchField: '',
      matchValue: '',
      termField: '',
      termValue: '',
      rangeField: '',
      rangeFrom: '',
      rangeTo: '',
      boolMust: '',
      boolShould: '',
      boolMustNot: '',
      generatedDsl: '',
      generatedCurl: '',
      showHelp: false,
    };
  },
  methods: {
    generateCurl() {
      // 根据输入生成DSL和CURL
      this.generateDsl();
      this.generateCurlCommand();
    },
    generateDsl() {
      // 根据查询类型和输入生成DSL
      let dsl = {
        query: {}
      };
      if (this.queryType === 'match') {
        dsl.query.match = {
          [this.matchField]: this.matchValue
        };
      } else if (this.queryType === 'term') {
        dsl.query.term = {
          [this.termField]: this.termValue
        };
      } else if (this.queryType === 'range') {
        dsl.query.range = {
          [this.rangeField]: {
            gte: this.rangeFrom,
            lte: this.rangeTo
          }
        };
      } else if (this.queryType === 'bool') {
        dsl.query.bool = {
          must: this.boolMust ? JSON.parse(this.boolMust) : [],
          should: this.boolShould ? JSON.parse(this.boolShould) : [],
          must_not: this.boolMustNot ? JSON.parse(this.boolMustNot) : []
        };
      }
      // 处理其他查询类型...
      
      this.generatedDsl = JSON.stringify(dsl, null, 2);
    },
    generateCurlCommand() {
      this.generatedCurl = `curl -X POST "${this.esUrl}/${this.indexName}/_search" -H 'Content-Type: application/json' -d '${this.generatedDsl}'`;
    },
    copyDsl() {
      navigator.clipboard.writeText(this.generatedDsl);
      this.$message.success('DSL已复制到剪贴板');
    },
    copyCurl() {
      navigator.clipboard.writeText(this.generatedCurl);
      this.$message.success('CURL命令已复制到剪贴板');
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
  },
};
</script>

<style scoped>
.generator-container {
  width: 90%;
  max-width: 1400px;
  margin: 20px auto;
  padding: 0 2%;
}

.box-card {
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
}

.card-title {
  font-size: 28px;
  color: #409EFF;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.el-form-item {
  margin-bottom: 25px;
}

.el-input, .el-select {
  width: 100%;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.el-button {
  padding: 12px 20px;
  font-size: 16px;
}

.el-input__inner, .el-textarea__inner {
  border-radius: 4px;
}

.el-input__inner:focus, .el-textarea__inner:focus {
  border-color: #409EFF;
  box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}

/* 添加一些动画效果 */
.el-button {
  transition: all 0.3s ease;
}

.el-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .generator-container {
    width: 95%;
    padding: 0 1%;
  }

  .card-title {
    font-size: 24px;
  }

  .button-group {
    flex-direction: column;
  }

  .el-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>