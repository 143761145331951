<template>
     <footer class="footer">
       <p class="text-center">&copy; 2024 CURL 请求生成器</p>
     </footer>
   </template>
   
   <script>
   export default {
     name: 'FooterComponent',
   };
   </script>
   
   <style scoped>
   .footer {
     background-color: #f5f5f5;
     padding: 10px 20px;
     text-align: center;
     position: fixed;
     bottom: 0;
     width: 100%;
     z-index: 1000;
     box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
   }
   </style>