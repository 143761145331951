<template>
  <el-card class="box-card help-card">
    <h2 class="help-title">{{ helpTitle }}</h2>
    <el-table :data="helpData" style="width: 100%" :stripe="true" :border="true">
      <el-table-column prop="parameter" label="参数" width="200"></el-table-column>
      <el-table-column prop="description" label="说明"></el-table-column>
      <el-table-column prop="example" label="示例" width="600"></el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'CurlHelp',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    helpTitle() {
      return this.type === 'elasticsearch' ? 'Elasticsearch 请求常用参数说明' : 'CURL 请求常用参数说明';
    },
    helpData() {
      return this.type === 'elasticsearch' ? this.elasticsearchParameters : this.curlParameters;
    },
  },
  data() {
    return {
      curlParameters: [
        { parameter: '-X, --request <command>', description: '指定请求方法，例如 GET、POST、PUT、DELETE 等。', example: 'curl -X POST https://api.example.com' },
        { parameter: '-H, --header <header>', description: '添加请求头信息。', example: 'curl -H "Content-Type: application/json" https://api.example.com' },
        { parameter: '-d, --data <data>', description: '发送 POST 数据。', example: 'curl -d "param1=value1&param2=value2" https://api.example.com' },
        { parameter: '--data-urlencode <data>', description: '发送 URL 编码的数据。', example: 'curl --data-urlencode "name=John Doe" https://api.example.com' },
        { parameter: '-u, --user <user:password>', description: '设置服务器的用户和密码。', example: 'curl -u username:password https://api.example.com' },
        { parameter: '-F, --form <name=content>', description: '模拟表单提交数据。', example: 'curl -F "file=@localfile.jpg" https://api.example.com/upload' },
        { parameter: '-I, --head', description: '仅获取响应头。', example: 'curl -I https://api.example.com' },
        { parameter: '-L, --location', description: '跟随重定向。', example: 'curl -L https://example.com' },
        { parameter: '-o, --output <file>', description: '将输出写入到文件。', example: 'curl -o output.html https://example.com' },
        { parameter: '-k, --insecure', description: '允许不安全的 SSL 连接。', example: 'curl -k https://self-signed.example.com' },
        { parameter: '-x, --proxy <[protocol://][user:password@]proxyhost[:port]>', description: '使用代理。', example: 'curl -x http://proxy.example.com:8080 https://api.example.com' },
        { parameter: '--max-time <seconds>', description: '设置最大传输时间。', example: 'curl --max-time 30 https://api.example.com' },
        { parameter: '--retry <num>', description: '失败时重试的次数。', example: 'curl --retry 3 https://api.example.com' },
      ],
      elasticsearchParameters: [
        { parameter: 'match', description: '匹配查询', example: '{ "query": { "match": { "field": "value" } } }' },
        { parameter: 'term', description: '精确匹配查询', example: '{ "query": { "term": { "field": "value" } } }' },
        { parameter: 'range', description: '范围查询', example: '{ "query": { "range": { "field": { "gte": "value1", "lte": "value2" } } } }' },
        { parameter: 'bool', description: '布尔查询', example: '{ "query": { "bool": { "must": [], "should": [], "must_not": [] } } }' },
        { parameter: 'multi_match', description: '多字段匹配查询', example: '{ "query": { "multi_match": { "query": "value", "fields": ["field1", "field2"] } } }' },
        { parameter: 'exists', description: '存在查询', example: '{ "query": { "exists": { "field": "field" } } }' },
        { parameter: 'prefix', description: '前缀查询', example: '{ "query": { "prefix": { "field": "value" } } }' },
        { parameter: 'wildcard', description: '通配符查询', example: '{ "query": { "wildcard": { "field": "value*" } } }' },
        { parameter: 'regexp', description: '正则表达式查询', example: '{ "query": { "regexp": { "field": "value.*" } } }' },
        { parameter: 'fuzzy', description: '模糊查询', example: '{ "query": { "fuzzy": { "field": "value" } } }' },
      ],
    };
  },
};
</script>

<style scoped>
.help-card {
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.help-title {
  font-size: 24px;
  color: #409EFF;
  margin-bottom: 20px;
  text-align: center;
}

.el-table {
  margin-top: 20px;
}

.el-table th {
  background-color: #409EFF !important;
  color: white;
  font-weight: bold;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: #f5f7fa;
}
</style>