<template>
  <div class="generator-container">
    <el-card class="box-card">
      <h2 class="card-title">CURL 请求生成器</h2>
      <el-form @submit.prevent="generateCurl" label-position="top">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="6">
            <el-form-item label="方法">
              <el-select v-model="method" placeholder="请选择" class="w-full">
                <el-option label="GET" value="GET"></el-option>
                <el-option label="POST" value="POST"></el-option>
                <el-option label="PUT" value="PUT"></el-option>
                <el-option label="DELETE" value="DELETE"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="18">
            <el-form-item label="URL">
              <el-input v-model="url" placeholder="https://example.com"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Headers">
          <div class="headers-container">
            <el-row :gutter="10" v-for="(header, index) in headers" :key="index" class="header-row">
              <el-col :span="10">
                <el-input v-model="header.key" placeholder="Key"></el-input>
              </el-col>
              <el-col :span="10">
                <el-input v-model="header.value" placeholder="Value"></el-input>
              </el-col>
              <el-col :span="4" class="button-col">
                <el-button type="danger" icon="el-icon-delete" circle @click="removeHeader(index)"></el-button>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="header-row">
              <el-col :span="20">
                <el-button type="primary" icon="el-icon-plus" @click="addHeader" class="add-header-btn">添加 Header</el-button>
              </el-col>
              <el-col :span="4" class="button-col">
                <!-- 占位，保持对齐 -->
              </el-col>
            </el-row>
          </div>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item label="认证">
              <el-select v-model="authType" placeholder="选择认证类型">
                <el-option label="无" value="none"></el-option>
                <el-option label="Basic" value="basic"></el-option>
                <el-option label="Bearer Token" value="bearer"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item label="编码">
              <el-select v-model="encoding" placeholder="请选择" @change="handleEncodingChange">
                <el-option label="UTF-8" value="UTF-8"></el-option>
                <el-option label="ISO-8859-1" value="ISO-8859-1"></el-option>
                <el-option label="GBK" value="GBK"></el-option>
                <el-option label="自定义" value="custom"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item label="ContentType">
              <el-select v-model="contentType" placeholder="请选择" @change="handleContentTypeChange">
                <el-option label="application/json" value="application/json"></el-option>
                <el-option label="application/x-www-form-urlencoded" value="application/x-www-form-urlencoded"></el-option>
                <el-option label="multipart/form-data" value="multipart/form-data"></el-option>
                <el-option label="text/plain" value="text/plain"></el-option>
                <el-option label="自定义" value="custom"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="6">
            <el-form-item label="代理设置">
              <el-input v-model="proxy" placeholder="http://proxy.example.com:8080"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6">
            <el-form-item label="超时设置">
              <el-input-number v-model="timeout" :min="0" :max="3600" placeholder="秒"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6">
            <el-form-item label="重试次数">
              <el-input-number v-model="retries" :min="0" :max="10"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="contentType === 'custom'">
          <el-col :span="24">
            <el-form-item label="自定义 ContentType">
              <el-input v-model="customContentType" placeholder="自定义 ContentType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Body" v-if="method === 'POST' || method === 'PUT'">
          <el-input
            type="textarea"
            v-model="body"
            :rows="6"
            placeholder='{"key": "value"}'
          ></el-input>
        </el-form-item>

        <el-form-item label="快捷选项">
          <el-checkbox v-model="insecure">允许不安全的 SSL 连接</el-checkbox>
          <el-checkbox v-model="outputToFile" class="ml-4">将输出写入到文件</el-checkbox>
          <el-input v-if="outputToFile" v-model="outputFileName" placeholder="文件路径" class="mt-2"></el-input>
        </el-form-item>

        <el-form-item label="生成的 CURL 命令">
          <el-input
            type="textarea"
            :rows="6"
            v-model="curlCommand"
            readonly
            class="curl-output"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="button-group">
            <el-button type="primary" @click="generateCurl" icon="el-icon-refresh">生成 CURL</el-button>
            <el-button type="success" @click="copyToClipboard" icon="el-icon-document-copy">复制到剪贴板</el-button>
            <el-button type="info" @click="resetForm" icon="el-icon-refresh-left">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <el-button type="primary" @click="toggleHelp" class="toggle-help">{{ showHelp ? '隐藏' : '显示' }}帮助信息</el-button>
    <CurlHelp v-if="showHelp" type="curl" />
  </div>
</template>

<script>
import CurlHelp from './CurlHelp.vue';

export default {
  name: 'CurlGenerator',
  components: {
    CurlHelp,
  },
  data() {
    return {
      url: '',
      method: 'GET',
      headers: [{ key: '', value: '' }],
      contentType: '',
      customContentType: '',
      encoding: '',
      customEncoding: '',
      body: '',
      curlCommand: '',
      insecure: false,
      outputToFile: false,
      outputFileName: 'output.txt',
      authType: 'none',
      username: '',
      password: '',
      token: '',
      proxy: '',
      timeout: 30,
      retries: 0,
      showHelp: false,
    };
  },
  watch: {
    url: 'generateCurl',
    method: 'generateCurl',
    headers: {
      handler: 'generateCurl',
      deep: true,
    },
    contentType: 'generateCurl',
    customContentType: 'generateCurl',
    encoding: 'generateCurl',
    customEncoding: 'generateCurl',
    body: 'generateCurl',
    insecure: 'generateCurl',
    outputToFile: 'generateCurl',
    outputFileName: 'generateCurl',
  },
  methods: {
    addHeader() {
      this.headers.push({ key: '', value: '' });
    },
    removeHeader(index) {
      this.headers.splice(index, 1);
    },
    handleContentTypeChange(value) {
      if (value !== 'custom') {
        this.customContentType = '';
      }
    },
    handleEncodingChange(value) {
      if (value !== 'custom') {
        this.customEncoding = '';
      }
    },
    toggleInsecure() {
      this.insecure = !this.insecure;
    },
    toggleOutputToFile() {
      this.outputToFile = !this.outputToFile;
    },
    generateCurl() {
      let command = `curl -X ${this.method} "${this.url}"`;
      
      // 添加认证
      if (this.authType === 'basic') {
        command += ` -u "${this.username}:${this.password}"`;
      } else if (this.authType === 'bearer') {
        command += ` -H "Authorization: Bearer ${this.token}"`;
      }

      // 添加代理
      if (this.proxy) {
        command += ` -x "${this.proxy}"`;
      }

      // 添加超时
      if (this.timeout > 0) {
        command += ` --max-time ${this.timeout}`;
      }

      // 添加重试
      if (this.retries > 0) {
        command += ` --retry ${this.retries}`;
      }

      let finalContentType = this.contentType === 'custom' ? this.customContentType : this.contentType;
      if (finalContentType) {
        command += ` -H "Content-Type: ${finalContentType}"`;
      }
      this.headers.forEach(header => {
        if (header.key && header.value) {
          command += ` -H "${header.key}: ${header.value}"`;
        }
      });
      if (this.body && (this.method === 'POST' || this.method === 'PUT')) {
        command += ` -d '${this.body}'`;
      }
      let finalEncoding = this.encoding === 'custom' ? this.customEncoding : this.encoding;
      if (finalEncoding) {
        command += ` --data-urlencode '${finalEncoding}'`;
      }
      if (this.insecure) {
        command += ' -k';
      }
      if (this.outputToFile) {
        command += ` -o ${this.outputFileName}`;
      }
      this.curlCommand = command;
    },
    resetForm() {
      this.url = '';
      this.method = 'GET';
      this.headers = [{ key: '', value: '' }];
      this.contentType = '';
      this.customContentType = '';
      this.encoding = '';
      this.customEncoding = '';
      this.body = '';
      this.insecure = false;
      this.outputToFile = false;
      this.outputFileName = 'output.txt';
      this.generateCurl();
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.curlCommand).then(() => {
        this.$message.success('CURL 请求已复制到剪切板');
      }).catch(() => {
        this.$message.error('复制失败');
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
  },
  mounted() {
    this.generateCurl(); // 初始化时生成 CURL 请求
  },
};
</script>

<style scoped>
.generator-container {
  width: 90%;
  max-width: 1400px;
  margin: 20px auto;
  padding: 0 2%;
}

.box-card {
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
}

.card-title {
  font-size: 28px;
  color: #409EFF;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.headers-container {
  margin-bottom: 10px;
}

.header-row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.button-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.curl-output {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f5f7fa;
  border-radius: 4px;
}

.el-form-item {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.el-button {
  padding: 12px 20px;
  font-size: 16px;
}

.el-input__inner, .el-textarea__inner {
  border-radius: 4px;
}

.el-input__inner:focus, .el-textarea__inner:focus {
  border-color: #409EFF;
  box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}

/* 添加一些动画效果 */
.el-button {
  transition: all 0.3s ease;
}

.el-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.mt-2 {
  margin-top: 8px;
}

.ml-4 {
  margin-left: 16px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .generator-container {
    width: 95%;
    padding: 0 1%;
  }

  .card-title {
    font-size: 24px;
  }

  .button-group {
    flex-direction: column;
  }

  .el-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .button-col {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .add-header-btn {
    width: auto;
    margin-top: 10px;
  }
}
</style>