<template>
  <header class="header">
    <div class="header-content">
      <div class="logo-container">
        <img src="../assets/curl-logo.png" alt="CURL Logo" class="logo">
      </div>
      <div class="title-container">
        <h1 class="main-title">CURL 请求生成器 <span class="subtitle">简化您的API测试流程,提高开发效率</span></h1>
      </div>
      <nav class="nav-menu">
        <router-link to="/" class="nav-link">通用 CURL</router-link>
        <router-link to="/es" class="nav-link">Elasticsearch CURL</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>

<style scoped>
.header {
  background: linear-gradient(135deg, #3498db, #8e44ad);
  padding: 15px;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo-container {
  margin-right: 20px;
}

.logo {
  width: 50px;
  height: 50px;
}

.title-container {
  flex-grow: 1;
}

.main-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.subtitle {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 15px;
  opacity: 0.9;
}

.nav-menu {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 20px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  z-index: -1;
}

.nav-link:hover::before {
  left: 0;
}

.nav-link.router-link-active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }

  .logo-container {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .main-title {
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    font-size: 0.9rem;
    margin-left: 0;
    margin-top: 5px;
  }

  .nav-menu {
    margin-top: 15px;
  }
}
</style>